.mlp_datasets_holder {
    width: 85%;
    display: block;
    margin-top: 2rem;
    grid-template-columns: 20rem 20rem 20rem 20rem 20rem;
    grid-row-gap: 2rem;
}

.mlp_new_datasource_card {
    width: 98%;
    height: fit-content;
    min-width: 50rem;
}

.mlp_new_ds_button {
    margin: 0.5rem;
}

.mlp_new_datasource_card .card-body {
    height: auto;
}

.mlp_dataset_card {
    margin: 0.5rem;
    width: 17rem;
}

.mlp_dataset_tags {
    width: 100%;
    height: 2rem;
    display: block;
}

.mlp_dataset_card .bx--structured-list {
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
}

.mlp_dataset_card .card-body {
    height: 8rem;
}

.mlp_datasets_container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 2rem;
}

.mlp_datasets_container .card-body {
    padding-top: 0.5rem;
}

.mlp_schema_info_card {
    width: 100%;
    min-height: 31rem;
    max-width: 95rem;
}

.mlp_new_datasource_card {
    width: 98%;
    height: fit-content;
    min-width: 50rem;
}

.mlp_new_ds_button {
    margin: 0.5rem;
    height: 2rem;
}
.mlp_new_ds_button button {
    float: right;
}
.mlp_new_datasource_card .card-body {
    height: auto;
}

.mlp_dataset_card .bx--structured-list {
    margin-top: 0.5rem;
}

.mlp_dataset_card .bx--structured-list-td {
    padding-top: 0.5rem;
    padding-bottom: 1rem;
}

.mlp_dataset_upload_holder .bx--form-item {
    display: block;
}

.mlp_dataset_modal_holder {
    width: 100%;
    padding-right: 1rem;
    text-align: left;
}

.mlp_dataset_drop_holder {
    overflow: visible !important;
}

.mlp_data_table_holder .cds--data-table-container .cds--data-table-content::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 3px;
}

.mlp_data_table_holder .cds--data-table-container .cds--data-table-content::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: rgba(0, 0, 0, .5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.mlp-dataset-shared-icon {
    margin-right: 0.5rem;
    margin-top: 0.4rem;
}

.mlp_data_card_menu {
    float: right;
}

.mlp-dataset-field-holder {
    width: 100%;
    height: 8rem;
    clear: both;
}
.mlp-dataset-field-holder textarea {
    width: 100%;
}

.mlp-dataset-sample-holder {
    margin-top: 1rem;
}

.mlp-dataset-section {
    float: left;
    clear: both;
    width: 100%;
    margin-top: 1rem;
}

.mlp-dataset-section-fit {
    float: left;
    clear: both;
    margin-top: 1rem;
}

.mlp-dataset-sharing-box {
    text-align: left;
    width: 100%;
    margin-bottom: 0.5rem;
}

.mlp-dataset-view {
    text-align: left;
    float: left;
    width: 100%;
}

.mlp-dataset-card-summary {
    width: 100%;
    float: left;
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-bottom: 0.5rem;
    margin-left: 0.5rem;
}

.mlp-dataset-card-name {
    font-weight: 700;
    font-size: 1.3rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid lightgray;
}

.mlp-dataset-card-ts {
    font-size: small;
}

.mlp-pointer {
    cursor: pointer;
    display: inline;
}