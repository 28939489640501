.mlp_home_holder {
    width: 80%;
    display: flex;
    justify-content: flex-end;
}

.mlp_home_holder .bx--structured-list {
    margin-bottom: 0rem;
}

.mlp_home_cards_holder {
    display: flex;
}

.mlp_home_explore_btn {
    min-width: 21rem;
}