.mlp_users_holder {
    width: 80%;
    display: block;
    margin-top: 3rem;
}

.mlp_users_tools {
    padding: 1rem;
    display: flex;
    max-width: 40rem;
    margin-left: 5.6rem;
}

.mlp_delete_selected {
    margin: 0.8rem;
    cursor: pointer;
}

.mlp_users_list {
    text-align: left;
}

.mlp_users_name {
    border-bottom: 1px solid gray;
    padding-bottom: 2rem;
    padding-top: 1rem;
    height: 2rem;
    display: block;
    width: 100%;
}

.mlp_users_list_content {
   height: 2rem;
}

.mlp_users_list_content span {
    float: left;
    margin-right: 1rem;
 }

.mlp_users_content {

}
.mlp_edit_user {

}