.mlp_risk_accordion_insights .bx--structured-list {
    margin-bottom: 1rem;
}

.mlp_risk_accordion_insights .cds--accordion__title {
    font-size: large;
}

.cds--accordion__item--active.mlp_risk_accordion_insights .cds--accordion__content {
    padding-right: 2rem;
}

.mlp-risk-content {
    min-height: 10rem;
    text-align: left;

}

.mlp-sharing-box {
    text-align: left;
    margin-top: 2rem;
    max-width: 30rem;
}

.mlp-sharing {
    text-align: left;
}

.mlp-sharing button {
    margin-top: 1rem;
}

.mlp-risk-section {
    float: left;
    clear: both;
    width: 100%;
    margin-top: 1rem;
}