.mlp_footer_holder {
    width: 100%;
    justify-content: center;
    text-align: center;
    display: flex;
}

.mlp_footer_holder a {
    margin-right: 2rem;
    margin-left: 2rem;
    display: flex;
    justify-content: center;
    color: #5D5D65FF !important;
}

.mlp_footer_container {
    max-height: 5rem;
}

.mlp_foot_copyright {
    width: 145rem;
}

.mlp_foot_link {
    width: 45rem;
}

.mlp_terms_and_cond {
    justify-content: center;
    display: flex;
}

#terms_and_cond {
    padding-top: 3rem;
    display: none;
    width: 80%;
}