.mlp_content_holder {
    padding-top: 1rem;
    min-width: 85%;
}

.mlp_center {
    justify-content: center;
    text-align: center;
    display: flex;
}

.mlp_center {
    justify-content: center;
    text-align: center;
    display: flex;
}

.mlp_block {
    display: block;
}

.mlp_right {
    justify-content: right;
    text-align: right;
    display: flex;
}

.mlp_absolute_center {
    position: absolute;
    top: calc(50% - 3rem);
    left: calc(50% - 3rem);
}

.mlp_absolute {
    position: absolute;
    z-index: 5;
}

.mlp_size_large {
    font-size: large;
}

.mlp_size_xxlarge {
    padding-top: 4rem;
    padding-top: 4rem;
    font-size: xx-large;
}

.mlp_size_xlarge {
    padding-top: 2rem;
    padding-bottom: 2rem;
    font-size: x-large;
}

.bold {
    font-weight: bold;
}

#notifications {
    z-index: 2;
    position: fixed;
    justify-content: center;
    text-align: center;
    display: flex;
    padding-top: 3rem;
    width: fit-content !important;
}

.mlp_churn_setup_table .bx--structured-list-td {
    padding-bottom: 0.3rem;
}

.mlp_no_padding {
    padding: 0rem;
}

.mlp_padding_1rem {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.mlp_padding_02rem {
    padding-top: 0.2rem;
}

.mlp_grid_1_2_3 {
    display: grid;
    grid-template-columns: 40% 30% 30%;
    grid-row-gap: 1rem;
    justify-content: center;
}

.mlp_grid_1_2_3_4 {
    display: grid;
    grid-template-columns: 20% 20% 20% 20%;
    grid-row-gap: 1rem;
    justify-content: center;
}

.mlp_grid_1_2 {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-row-gap: 1rem;
    justify-content: center;
    justify-items: center;
}

.mlp_grid_1_2_nogap {
    grid-row-gap: 0rem !important;
}

.mlp_grid_1_2_medium {
    grid-col-gap: 2rem;
    min-width: 12rem;
}

.mlp_grid_logs {
    display: grid;
    grid-template-columns: 25% 63% 12%;
    grid-row-gap: 0.3rem;
}

.mlp_ontop {
    z-index: 3;
    justify-content: center;
    text-align: center;
    display: flex;
}

.mlp_larger_msg {
    min-width: 35rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.mlp_larger_msg .mlp_grid_1_2 {
    text-align: left;
}

.mlp_loading_graph_holder_600_400 {
    min-width: 500px;
    min-height: 300px;
    justify-content: center;
    display: flex;
    padding-top: 90px;
    margin: 1rem;
    opacity: 0.7;
    background-image: url("bg_chart.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
}

.cds--link:not([href]) {
    cursor: pointer;
    pointer-events: all;
    touch-action: auto;
}

.mlp_right .bx--inline-loading {
    display: flex;
    justify-content: right;
    padding-right: 2rem;
}

.mlp_left {
    text-align: left;
    justify-content: left;
    width: 100%;
}

.cds--loading {
    width: 4.5rem;
    height: 4.5rem;
}

.bx--data-table td {
    vertical-align: middle;
}

.cds--data-table tbody td {
    text-align: inherit;
}

.mlp_color_blue {
    color: blue;
}

.mlp_color_green {
    color: green;
}

.mlp_color_gray {
    color: gray;
}

.mlp_color_orange {
    color: orange;
}

.mlp_color_red {
    color: red;
}

.bx--tile-content {
    height: auto;
}

.mlp_italic_text {
    font-style: italic;
}

.mlp_text_bold {
    font-weight: bold;
}

.bx--tile__chevron {
    display: none;
}

.mlp_model_file_error {
    color: red;
    font-weight: bold;
}

.mlp_model_uploader {
    padding-top: 0rem;
}

.cds--label-description {
    margin-bottom: 0rem;
}

.mlp_save_cancel_buttons {
    float: right;
    padding-top: 2rem;
}

.mlp_label_encodings_list {
    max-height: 21rem;
    overflow: scroll;
}

.mlp_chart_grid {
    justify-content: center;
    padding-top: 1rem;
    grid-row-gap: 4rem;
}

.mlp_datasets_description {
    min-width: 50rem;
}