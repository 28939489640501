.mlp_churn_holder {
    width: 100%;
    display: flex;
    justify-content: center;
}

.mlp_churn_tab_holder {
    max-width: 100% !important;
    width: 30%;
}

.mlp_churn_tabs_list_holder .cds--tab--list {
    max-width: 100%;
    width: 100%;
}

.mlp_churn_nav {
    margin-top: 2rem;
}

.mlp_churn_nav > *:first-child {
    margin-right: 1rem;
}

.mlp_dataset_card:hover {
    cursor: pointer !important;
}

.cds--list-box--expanded {
    z-index: 1000;
    position: absolute;
    width: 25rem;
    display: contents;
}

.mlp_churn_drop_role {
    width: 25rem;
}

.mlp_churn_drop_role .cds--dropdown--open .cds--list-box__menu {
    min-height: 20rem;
}

.mlp_data_fields_table {
    max-width: 48rem;
}

.mlp_data_fields_Name {
    min-width: 12rem;
}



.mlp_churn_setup_holder {
    display: flex;
    justify-content: left;
    text-align: left;
    width: 18rem;
    min-height: 4rem;
}

.mlp_churn_setup_tab {
    display: flex;
    justify-content: center;
    text-align: left;
    min-height: 14rem;
}


.mlp_churn_run_holder {
    display: flex;
    justify-content: center;
    text-align: left;
    width: 100%;
    min-height: 4rem;
}

.cds--accordion__item--active.mlp_churn_accordion_insights .cds--accordion__content {
    padding-right: 1rem;
}

.mlp_churn_ml_model_holder {
    display: grid;
    grid-template-columns: 40% 60%;
    grid-column-gap: 8rem;
    min-width: 90rem;
}

.mlp_churn_ml_holder {
    width: 95rem;
}

.mlp_churn_ml_model_holder_acc {
    padding-top: 6rem;
}

.mlp_churn_setup_table {
    margin-left: 7rem;
    margin-top: 3rem;
    min-width: 14rem;
}

.mlp_churn_setup_table .cds--data-table-container .bx--structured-list {
    min-width: 24rem;
    margin-bottom: 0;
}

.mlp_churn_run_insights .bx--structured-list-td {
    padding: 0.3rem;
}

.mlp_churn_run_insights {
    justify-content: left;
    text-align: left;
    display: flex;
    margin-bottom: auto;
}

.mlp_churn_accordion_insights .cds--accordion__title {
    font-size: large;
}

.mlp_churn_progress {
    display: inline-flex;
    align-items: baseline;
}

.mlp_churn_accordion_progress {
    display: grid;
    grid-template-columns: 70% 30%;
    min-width: 80rem;
}

.mlp_churn_dataset_description .bx--tile--is-expanded {
    z-index: 1000;
    display: block;
}

.mlp-churn-content {
    margin-top: 1rem;
    display: box;
    justify-content: center;
    text-align: left;
    min-height: 4rem;
}

.mlp-sharing-box {
    text-align: left;
    margin-top: 2rem;
    max-width: 30rem;
}

.mlp-sharing {
    text-align: left;
}

.mlp-sharing button {
    margin-top: 1rem;
}

.mlp-churn-section {
    float: left;
    clear: both;
    width: 100%;
    margin-top: 1rem;
}