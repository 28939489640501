.mlp_card_info {
    border: 1px solid #9a9a9a !important;
    box-shadow: 1px 1px #9a9a9a;
    width: 18rem;
    min-width: 14.5rem;
    margin-right: 1rem;
    margin-left: 1rem;
}

.mlp_card_info:hover {
    cursor: help;
    border: 1px solid black !important;
}

.mlp_card_info:focus {
    box-shadow: 4px 4px 8px;
    outline: none;
}

.mlp_card_info .bx--structured-list {
    min-width: auto;
}

.mlp_card_info .card-body {
    height: 14rem;
}

.mlp_card_info_nohelp:hover {
    cursor: default !important;
}

.mlp_card_desc {
    padding-top: 0.5rem;
    max-width: 16rem;
}

.mlp_deploy_btn {
    padding: 0.1rem;
}

.mlp_deploy_btn button {
    width: 14rem;
}

.mlp_card_disabled {
    pointer-events: none;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=60)"; /* IE 8 */
    filter: alpha(opacity=60); /* IE 5-7 */
    -moz-opacity: 0.6; /* Netscape */
    -khtml-opacity: 0.6; /* Safari 1.x */
    opacity: 0.6; /* Good browsers */
}

.mlp_case_actions {
    padding-top: 1em;
    height: 3rem;
    display: flex;
    justify-content: center;
}

.mlp_case_actions button {
    min-height: 2.14rem;
    padding: calc(0.375rem - 3px) 60px calc(0.375rem - 3px) 12px;
}

.bx--modal-footer .bx--btn--secondary {
    visibility: hidden;
}

.mlp_case_details {
    margin-top: 2rem;
    margin-bottom: 2rem;
}


.mlp_datasets_small_holder .bx--structured-list-td {
    padding-top: 0.2rem;
    padding-bottom: 0.3rem;
}

.mlp_datasets_small_holder {
    display: flex;
    min-width: calc(100% / 5);
    padding-top: 2rem;
}

.mlp_schema_info_card {
    width: 100%;
    min-height: 31rem;
    max-width: 95rem;
}

.mlp_status_text_Ready {
    color: green !important;
}

.mlp_status_text_Fail {
    color: red !important;
}

.mlp_status_text_Deploying {
    color: blue !important;
}

.mlp_status_text_NotDeployed {
    color: gray !important;
}

.mlp_action_btn_sm {
    width: 6rem;
}

.bx--modal-header, .bx--modal-content {
    width: 100%;
}

.mlp_dataset_upload_holder .bx--form-item {
    display: block;
}

.mlp_dataset_modal_holder {
    padding-left: 1rem;
    width: 100%;
    padding-right: 1rem;
}

.mlp_dataset_drop_holder {
    overflow: visible !important;
}

.mlp_model_info_holder {
    display: grid;
    grid-template-columns: 80%;
    grid-row-gap: 1rem;
}

.mlp_model_info_line_holder {
    display: grid;
    grid-template-columns: 20% 40% 30%;
    grid-row-gap: 1rem;
}

.mlp_model_info_metrics_holder {
    width: 20rem;
}

.mlp_code_snippet .cds--snippet--multi {
    max-width: inherit;
}

.mlp_code_snippet .cds--snippet--multi .cds--snippet-container {
    width: inherit;
}

.mlp_data_table_header .cds--table-sort__icon-unsorted {
    margin-left: 1.2rem;
}

.bx--tile:focus {
    outline: 0;
}

.mlp_pred_models_holder * {
    margin: auto;
    text-align: left;
}

.mlp_pred_models_holder {
    display: grid;
    grid-template-columns: 5% 30% 25% 35%;
    grid-column-gap: 1rem;
    min-width: 45rem;
}

.mlp_pred_models_parent {
    margin-top: 2rem;
    margin-bottom: 1rem;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-column-gap: 2rem;
    min-width: 86rem;
    width: 95%;
}

.mlp_pred_predictions_parent {
    margin-top: 2rem;
    margin-bottom: 1rem;
    display: grid;
    grid-template-columns: 35% 65%;
    grid-column-gap: 2rem;
    min-width: 80rem;
    width: 95%;
}

.mlp_pred_description {
    max-width: 50rem;
    padding-left: 0.5rem;
}

.mlp_header_colors {
    background-color: #e0e0e0;
    padding: 0.5rem;
    margin-bottom: 0.3rem;
    color: black;
}

.mlp_schema_table_scroll {
    overflow-y: scroll;
    max-height: 20rem;
}

.mlp_schema_table_scroll .cds--data-table-content {
    overflow-x: visible;
}

.mlp_10_width {
    min-width: 10rem;
}

.mlp_new_width {
    min-width: 85%;
}

.mlp_data_table_holder .cds--data-table-container .cds--data-table-content::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 3px;
}

.mlp_data_table_holder .cds--data-table-container .cds--data-table-content::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: rgba(0, 0, 0, .5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.mlp-dataset-shared-icon {
    margin-right: 0.5rem;
    margin-top: 0.4rem;
}

.mlp_data_card_menu {
    float: right;
}

.mlp-ds-tag {
    float: left;
}