.mlp_datasets_body {
    display: block;
    text-align: center;
}


div.mlp_risk_setup_tab .cds--label {
    font-weight: 700;
    font-size: small;
}

.mlp_risk_setup_tab {
    display: flex;
    justify-content: center;
    text-align: left;
    min-height: 14rem;
}

.mlp_sent_accordion_insights .bx--structured-list {
    margin-bottom: 1rem;
}

.mlp_sent_accordion_insights .bx--structured-list-row {
    border: none;
}

.mlp_sentiment_holder {
    width: 100%;
    display: box;
    justify-content: center;
    text-align: left;
}

.mlp_sentiment_holder section {
    border-collapse: unset;
}

.mlp_sent_accordion_insights .cds--accordion__title {
    font-size: large;
}

.cds--accordion__item--active.mlp_sent_accordion_insights .cds--accordion__content {
    padding-right: 2rem;
}

.mlp_sentiment_predict_holder {
    display: flex;
    justify-content: center;
    text-align: left;
    width: 100%;
    min-height: 4rem;
}

.mlp_sentiment_ratio_donut {
    margin-left: 2rem;
    margin-bottom: 1rem;
}

.mlp_sentiment_ratio_donut .cds--cc--donut {
    margin-left: 8rem;
}

.mlp_sentiment_ratio_donut .cds--cc--radar {
    margin-left: 8rem;
}

.mlp_sentiment_ratio_donut .cds--cc--pie {
    margin-left: 8rem;
}

.mlp_sent_grid_1 {
    display: grid;
    grid-template-columns: 70% 30%;
    grid-row-gap: 1rem;
    justify-content: center;
    justify-items: center;
}

.mlp_sent_grid_2 {
    display: grid;
    grid-template-columns: 30% 70%;
    grid-row-gap: 1rem;
    justify-content: center;
    justify-items: center;
}

.mlp_sent_middle_table {
    display: table-cell;
    vertical-align: middle;
}

.mlp-sharing-box {
    text-align: left;
    margin-top: 2rem;
    max-width: 30rem;
}

.mlp-sharing {
    text-align: left;
}

.mlp-sharing button {
    margin-top: 1rem;
}

.mlp-sentiment-section {
    float: left;
    clear: both;
    width: 100%;
    margin-top: 1rem;
}

.mlp-sentiment-content {
}