.mlp_header_holder {
    display: flex;
    justify-content: flex-end;
    width: 70%;
}

#mlp_head_info {
    padding-top: 3rem;
    position: absolute;
    right: 1em;
    z-index: 3;
}

.mlp_header_app_title {
    min-width: 24rem;
}

.mlp_header_app_title .bx--header__name--prefix {
    margin-right: 0.5rem;
}

svg.login_icon {
    fill: white;
    margin-top: 0.5rem;
    margin-right: 0.3rem;
    cursor: pointer;
}

.mlp_login_form .cds--label {
    font-weight: 500;
    font-size: medium;
}

.mlp_login_form input {
    margin-bottom: 1rem;
}