.mlp_ops_holder {
    width: 85%;
    display: block;
    margin-top: 3rem;
}

.mlp_ops_holder .bx--structured-list {
    margin-bottom: 0rem;
}

.mlp_ops_exp_panel1 {
    border-bottom: 1px solid rgb(201, 200, 200);
    display: flex;
}

.mlp_ops_exp_panel1 h4 {
    padding-bottom: 1rem;
}

.mlp_ops_exp_panel2 {
    display: block;
    padding-top: 0.5rem;
    text-align: left;
}

.text_field {
    padding-top: 0.5rem;
    padding-bottom: 1rem;
}

.mlp_ops_exp_panel2 textarea {
    width: 100%;
    height: 8rem;
}

.mlp_ops_empty_card {
    display: block;
    width: 21rem;
    min-height: 4rem;
    padding: 1rem;
    clear: both;
}

.mlp_ops_modelbox {
    display: block;
    width: 20rem;
    background-color: white;
    padding: 1rem;
    float: left;
    margin-top: 1rem;
    margin-right: 0.5rem;
    margin-left: 0.5rem;
    border-radius: 0.5rem;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.mlp_ops_box_item_component {
    width: 100%;
}

.mlp_ops_box_item_component .outer {
    display: block;
}

.mlp_ops_box_item_component .highlighted .outer {
    padding-top: 11rem;
    transition: padding-top 100ms ease-out;
}

.mlp_ops_modelbox:hover {
    background-color: #fafafa;
}

.mlp_ops_model_panel {
    width: 100%;
    min-height: 30rem;
    padding: 1rem;
    display: block;
    float: left;
    margin: 1rem;
    background-color: rgb(250, 250, 250);
    border: 0.1rem solid rgb(222, 222, 222);
    text-align: left;
}

.mlp_ops_model_panel .ddcontainer {
    margin-top: 0.5rem;
}

.mlp_ops_model_panel_title {
    display: block;
    height: 2rem;
    border-bottom: 1px solid rgb(200, 200, 200);
    padding-bottom: 2rem;
    font-weight: 700;
}

.mlp_ops_card_title {
    float: left;
    height: 1rem;
    font-weight: 700;
    display: flex;
}

.mlp_ops_card_title-name {
    margin-top: 0.4rem;
}

.mlp_ops_action_menu {
    float: right;
    margin-top: -0.2rem;
}

.mlp_ops_meta {
    clear: both;
    font-size: small;
    padding-top: 0.3rem;
    padding-bottom: 1rem;
    padding-right: 0.5rem;
    border-bottom: 1px solid rgb(200, 200, 200);
    height: 5rem;
}

.mlp_ops_meta div {
    margin-top: 0.3rem;
    clear: both;
}

.mlp_ops_case {
    font-size: small;
    display: inline-block;
    margin-top: 0.5rem;
}

.mlp_ops_labels {
    clear: both;
    margin-top: 0.3rem;
    min-height: 2rem;
}


.mlp_ops_approval_icon {
    float: right;
    padding: 0.2rem;
    margin-right: 0.3rem;
}

.mlp_ops_model_wait {
    float: right;
    padding: 0rem;
}

.mlp_ops_model_wait .cds--loading {
    width: 1.5rem;
    height: 1.5rem;
}

.mlp_model_ready {
    border-left: 4px solid rgb(0, 200, 0);
}

.mlp_ops_model_expand {
    padding-top: 0.2rem;
    float: left;
    margin-right: 0.3rem;
}

.mlp_ops_model_expand:hover {
    cursor: pointer;
}

.mlp_ops_panel_expand {
    float: right;
}

.mlp_ops_panel_expand:hover {
    cursor: pointer;
}

div.mlp_ops_holder .cds--label {
    font-weight: 700;
    font-size: medium;
}

div.mlp_ops_holder .cds--file--label {
    font-weight: 700;
    font-size: medium;
    margin-bottom: 0.3rem;
}

div.mlp_ops_holder .select {
    justify-content: left;
    text-align: left;
    
}

.mlp_ops_tools {
    display: block;
    padding-left: 1rem;
}

.mlp_ops_search {
    width: 23rem;
    float: left;
}

.mlp_ops_upload_model {
    float: right;
    justify-content: right;
    padding-right: 1rem; 
}

.mlp_ops_row {
    width: 100%;
}

.mlp_ops_content {
    display: flex;
    flex-direction: column;
}

.mlp_ops_createpredictor {
    display: block;
}

.mlp_ops_createpredictor span {
    font-size: smaller;
}

.mlp_ops_predictors_panel {
    margin-top: 1rem;
    width: 73rem;
}


.mlp_labels_table {
    
}

.cds--file__selected-file .cds--file-filename {
    margin-left: 0rem;
}

.mlp_left_cells {
    max-width: 20rem;
}

.mlp_upload_schema_container {
    padding-top: 2rem;
}
.mlp_upload_schema_container .cds--accordion__title {
    font-weight: 700;
}

.mlp_upload_schema_container .bx--structured-list-row {
    border: none;
    
}

.mlp_upload_schema_container .cds--accordion__content {
    padding: 0;
}

.mlp_schema_table {
    padding-left: 1rem;
    padding-right: 1rem;
}

.mlp_schema_field_name {
    padding-left: 1rem;
    padding-right: 1rem;
    width: 20rem;
    float: left;
}

.mlp_schema_field_type {
    padding-left: 1rem;
    padding-right: 1rem;
    width: 10rem;
    float: left;
}

.mlp_schema_add_field {
    padding-top: 2rem;
    padding-left: 1rem;
    float: left;
}

.mlp_models_list {
    width: 100%;
    text-align: left;
    margin-top: 2rem;
}

.mlp-model-sharing {
    clear: both;
}
.mlp-model-sharing div {
    cursor: pointer;
    margin-right: 1rem;
    margin-bottom: 0.7rem;
    float: left;
}

.mlp-model-sharing-tags {
    margin-right: 0rem;
    margin-top:-0.3rem;
    float: left
}

.mlp-clickable {
    cursor: pointer;
}

.mlp-model-update-close {
    margin-left: 0.5rem;
    border-bottom: 1px solid lightgray;
    clear: both;
}

.mlp_ops_shared {
    float: right;
    margin-top: 0.2rem;
    margin-right: 0.3rem;
}

.mlp-model-sharing-box {
    text-align: left;
    width: 100%;
}