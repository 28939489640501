div{
    vertical-align: top;
}

.bx--label {
    font-weight: 700;
    font-size: medium;
}

.mlp_main_holder {
    width: 80%;
    display: flex;
    justify-content: flex-end;
}

.mlp_main_cards_holder {
    margin-top: 1rem;
    display: flex;
}

.mlp_main_card_info {
    width: 18rem;
    margin-right: 3rem;
}

.mlp_main_nocase {
    padding-top: 1em;
    padding-bottom: 1em;
}

.mlp_main_details_holder .mlp_case_details {
    padding-top: 0rem;
}

.mlp_main_use_case_head {
    padding-bottom: 0.3em;
    font-size: x-large;
}

.mlp_main_use_case_holder {
    padding-top: 2rem;
    width: 90%;
    display: inline-block;
}

.mlp_main_cases_holder {
    min-width: 75rem;
    padding-bottom: 1rem;
}


.mlp_main_accordion_item .cds--accordion__heading {
    min-width: 98%;
}

.mlp_main_accordion_item .cds--accordion__content {
    padding: 1rem;
}


.mlp_main_tasks_x_tasks {
    float: right;
    margin-right: 2rem;
}

.mlp_main_demo_btn button {
    min-height: 1rem;
    padding: 0.2rem;
}

.mlp-jobs-author-name {
    margin-left: 0.5rem;
}